const tabCntnt = (() => {

  const pageTop = (document.getElementById("pageTop") != null);

  if (pageTop) {
    // console.log("object");
    let tab = document.querySelectorAll('.js-btn');
    let cntnt = document.querySelectorAll('.js-cntnt');
    let scrollTop = document.getElementById('jsScrollTop');

    for (let i = 0; i < tab.length; i++) {
      tab[i].addEventListener("click", () => {

        // 押されたiとjが同じだったらactとらない
        for (let j = 0; j < tab.length; j++) {
          if (!(j == i)) {
            // console.log(j);
            tab[j].classList.remove("act");
            tab[j].classList.add("none");
            cntnt[j].classList.remove("act");
            // arrow[j].classList.remove("act");
          }
        }

        tab[i].classList.add('act');
        tab[i].classList.remove("none");
        cntnt[i].classList.add('act');
        scrollTop.classList.add('act')
      });
    }

  }

})();
export default tabCntnt;